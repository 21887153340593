<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="demandes"
      :items-per-page="10"
      :loading="loading"
      sort-by="id"
      :sort-desc="true"
      loading-text="Chargement... Veuillez patienter"
      show-expand
      class="elevation-1"
      :footer-props="{
        itemsPerPageAllText: 'Tout',
        itemsPerPageText: 'Lignes par page :',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gestion de vos demandes d'absences</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogNew" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Faire une demande</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Nouvelle demande d'absence</span>
              </v-card-title>
              <v-card-text>
                <v-container style="padding:0px">
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="demande.type" :items="types" label="Choisir un motif d'absence" solo item-text="name" item-value="id"></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                        v-model="menuStartDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="demande.startDate" label="Date de début" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="demande.startDate" no-title scrollable @input="menuStartDate = false" :first-day-of-week="1" locale="fr-fr" :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                      <v-radio-group v-model="demande.startType">
                        <v-radio label="Matin" :value="0"></v-radio>
                        <v-radio label="Apres-midi" :value="1"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                        v-model="menuEndDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="demande.endDate" label="Date de fin" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="demande.endDate" no-title scrollable @input="menuEndDate = false" :first-day-of-week="1" locale="fr-fr" :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                      <v-radio-group v-model="demande.endType">
                        <v-radio label="Matin" :value="0"></v-radio>
                        <v-radio label="Apres-midi" :value="1"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="demande.duration" label="Durée en jours" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows="2" v-model="demande.description" label="Description de la demande"></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="save">Envoyer ma demande</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-list-item-icon>
          <v-list-item>
            <v-icon @click="deleteItem(item)" v-if="item.status=='STATUT_CREER'">mdi-delete</v-icon>
          </v-list-item>
        </v-list-item-icon>
      </template>
      <template v-slot:[`item.type`]="{ item }">{{item.type.name}}</template>
      <template v-slot:[`item.startDate`]="{ item }">{{item.startDate|formatDate}} - <span v-if="item.startType==0">Matin</span><span v-else>Après-midi</span></template>
      <template v-slot:[`item.endDate`]="{ item }">{{item.endDate|formatDate}} - <span v-if="item.endType==0">Matin</span><span v-else>Après-midi</span></template>
      <template v-slot:[`item.description`]="{ item }">{{item.description|truncateDescription}}</template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip v-if="item.status=='STATUT_ACCEPTE'" class="ma-2" color="green" text-color="white">{{item.status}}</v-chip>
        <v-chip v-else-if="item.status=='STATUT_CREER'" class="ma-2" color="warning" text-color="white">{{item.status}}</v-chip>
        <v-chip v-else class="ma-2" color="red" text-color="white">{{item.status}}</v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <ul class="nav nav-tabs" style="font-size: 15px">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#historique"><b>Historique</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#description"><b>Description</b></a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade active show" id="historique">
                <ul class="timeline list-unstyled" v-for="commentaire in item.commentaire" :key="commentaire.id">
                    <li v-if="commentaire.status=='STATUT_ACCEPTE'" class="timeline-item bg-white rounded ml-3 p-4 shadow">
                        <div class="timeline-arrow"></div>
                        <h2 class="contentText mb-0">La demande a été <b>acceptée</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b> avec pour message : {{ commentaire.texte }}</h2>
                        <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                    </li>
                    <li v-else-if="commentaire.status=='STATUT_CREER'" class="timeline-item bg-white rounded ml-3 p-4 shadow">
                        <div class="timeline-arrow"></div>
                        <h2 class="contentText mb-0">La demande a été <b>créer</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b></h2>
                        <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                    </li>
                    <li v-else-if="commentaire.status=='STATUT_REFUSE'" class="timeline-item bg-white rounded ml-3 p-4 shadow">
                        <div class="timeline-arrow"></div>
                        <h2 class="contentText mb-0">La demande a été <b>refusé</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b> avec pour message : {{ commentaire.texte }}</h2>
                        <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                    </li>
                    <li v-else class="timeline-item bg-white rounded ml-3 p-4 shadow">
                        <div class="timeline-arrow"></div>
                        <h2 class="contentText mb-0">La demande a été <b>supprimée</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b> avec pour message : {{ commentaire.texte }}</h2>
                        <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                    </li>
                </ul>
              </div>
              <div class="tab-pane fade show" id="description">
                <div class="jumbotron mt-3">
                  {{item.description}}
                </div>
              </div>
            </div>
          </v-container>
        </td>
      </template>
      <template slot="footer.page-text" slot-scope="item">
        {{item.pageStart}}-{{item.pageStop}} sur {{item.itemsLength}}
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="700px">
      <v-card>
          <v-card-title class="headline">Êtes-vous sûr de vouloir faire une demande de suppression ?</v-card-title>
          <v-card-text>
            <v-text-field v-model="raisonDelete" label="Raison de la demande de suppression"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" absolute top >{{ text }}</v-snackbar>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data () {
      return {
        loading: true,
        color: '',
        snackbar: false,
        text: '',
        timeout: 4000,
        menuStartDate: false,
        menuEndDate: false,
        dialogNew: false,
        dialogDelete: false,
        editedIndex: -1,
        raisonDelete: '',
        headers: [
          { text: 'Motif de l\'absence', align: 'start', value: 'type', sortable: false },
          { text: 'Description', value: 'description', sortable: false },
          { text: 'Date de début', value: 'startDate', sortable: false },
          { text: 'Date de fin', value: 'endDate', sortable: false },
          { text: 'Durée en jours', value: 'duration', sortable: false },
          { text: 'Etat de la demande', value: 'status', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        demandes: [],
        types: [],
        demande: {
          type: '',
          startDate: new Date().toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10),
          startType: 0, 
          endType: 1, 
          commentaire: '',
          status: 'STATUT_CREER',
          idGoogleEvent: '',
          duration: 1,
          description: '',
        }
      }
    },

    mounted() {
      this.updateTable();
      axios
        .get("/v2/agenda/type")
        .then((response) => {
            if(response.data.success){
                this.types = response.data.message;
            }else{
                this.color = "warning"
                this.text = "Erreur au chargement. Merci de recharger la page."
                this.snackbar = true;
            }
            
        })
        .catch(function(error){console.log(error)});
    },

    methods: {
      updateTable() {
        this.loading = true
        axios
          .get("/v2/agenda/demande/")
          .then((response) => {
            this.loading = false
            if(response.data.success){
              this.demandes = response.data.message;
              this.color = "success"
              this.text = "Toutes les données ont bien été chargées !"
              this.snackbar = true;
            }else{
              this.color = "warning"
              this.text = response.data.message
              this.snackbar = true;
            }
          })
          .catch(this.logResponseErrors);
      },

      close() {
        this.dialogNew = false
      },

      closeDelete() {
        this.dialogDelete = false
      },

      deleteItem (item) {
        this.editedIndex = item.id
        this.dialogDelete = true
      },

      deleteItemConfirm(){
        let data = new FormData();
        data.set("status", "STATUT_SUPPRIME");
        data.set("texte", (this.raisonDelete.slice(-1)==".")?this.raisonDelete:this.raisonDelete+".");
        axios
        .post("/v2/agenda/demande/addstatus/"+this.editedIndex, data)
        .then((response) => {
            if(response.data.success){
                this.updateTable();
                this.closeDelete()
            }else{
              this.color = "warning"
              this.text = response.data.message
              this.snackbar = true;
            }
        })
        .catch(this.logResponseErrors);
      },

      save() {
        if(this.demande.type == ''){
          this.color = "warning"
          this.text = "Veuillez-choisir un type d'absence"
          this.snackbar = true;
        }else{
          let data = new FormData();
          Object.keys(this.demande).forEach(key=>{
            data.set(key,this.demande[key])
          })
          axios
          .post("/v2/agenda/demande/create", data)
          .then((response) => {
            if(response.data.success){
                this.dialogNew = false
                this.updateTable();
                this.demande = {
                  type: '',
                  startDate: new Date().toISOString().substr(0, 10),
                  endDate: new Date().toISOString().substr(0, 10),
                  startType: 0, 
                  endType: 1, 
                  commentaire: '', 
                  status: 'STATUT_CREER',
                  idGoogleEvent: '',
                  duration: 1,
                  description: '',
                }
            }else{
              this.color = "warning"
              this.text = response.data.message
              this.snackbar = true;
            }
          })
          .catch(this.logResponseErrors);
        }
      },

      updateDuration() {
        let dateDebut= new Date(this.demande.startDate).getTime();
        let dateFin= new Date(this.demande.endDate).getTime();
        let difference= (dateFin-dateDebut);
        difference = difference/((1000*3600)*24);
        if(this.demande.startType === this.demande.endType){
          difference = difference+0.5;
        }else if(!this.demande.startType && this.demande.endType){
          difference = difference+1;
        }
        this.demande.duration=difference;
      },
      allowedDates(val){
        return parseInt((new Date(val)).getDay()) % 6 != 0;
      },
    },
    watch: {
      'demande.startDate': {
        handler: function (){
          if (new Date(this.demande.startDate) > new Date(this.demande.endDate)) {
            this.demande.endDate = this.demande.startDate
          }
          this.updateDuration();
        }
      },
      'demande.endDate': {
        handler: function (){
          if (new Date(this.demande.startDate) > new Date(this.demande.endDate)) {
            this.demande.startDate = this.demande.endDate
          }
          this.updateDuration();
        }
      },
      'demande.startType': {
        handler: function (){
          this.updateDuration();
        }
      },
      'demande.endType': {
          handler: function (){
            this.updateDuration();
          }
      }
    }
  }
</script>

<style>
ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
}

ul.timeline:before {
    content: ' ';
    background: #1976d2;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
}

li.timeline-item {
    margin: 20px 0;
    font-size: 15px
}

.timeline-arrow {
    border-top: 0.5rem solid transparent;
    border-right: 0.5rem solid #fff;
    border-bottom: 0.5rem solid transparent;
    display: block;
    position: absolute;
    left: 2rem;
}

.contentText {
  font-size: 15px;
  font-weight: initial;
}

li.timeline-item::before {
    content: ' ';
    background: #fff;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #1976d2;
    left: 11px;
    width: 14px;
    height: 14px;
    z-index: 400;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

</style>