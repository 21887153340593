<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="demandes"
      :items-per-page="5"
      :loading="loading"
      loading-text="Chargement... Veuillez patienter"
      sort-by="id"
      :search="search"
      :sort-desc="true"
      show-expand
      class="elevation-1"
      :footer-props="{
        itemsPerPageAllText: 'Tout',
        itemsPerPageText: 'Lignes par page :',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Demandes en cours</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-row class="mt-5">
                <v-col cols="3">
                    <v-select :items="listUtilisateurs" v-model="employeFilterValue" label="Nom de l'employé" ></v-select>
                </v-col>
                <v-col cols="3">
                    <v-select :items="listStatus" v-model="statutFilterValue" label="Statuts" ></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher dans une description" single-line hide-details></v-text-field>
                </v-col>
            </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-list-item-icon>
            <v-list-item>
                <v-icon v-if="item.status == 'STATUT_CREER'" color="green" @click="validateItem(item)" class="mr-2">mdi-check</v-icon>
                <v-icon v-if="item.status == 'STATUT_CREER'" color="red" @click="refuseItem(item)">mdi-close</v-icon>
                <v-icon v-if="item.status == 'STATUT_ACCEPTE'" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </v-list-item>
        </v-list-item-icon>
      </template>
      <template v-slot:[`item.utilisateur`]="{ item }">{{ item.utilisateur.firstName }} {{ item.utilisateur.lastName }}</template>
      <template v-slot:[`item.type`]="{ item }">{{item.type.name}}</template>
      <template v-slot:[`item.startDate`]="{ item }">{{item.startDate|formatDate}} - <span v-if="item.startType==0">Matin</span><span v-else>Après-midi</span></template>
      <template v-slot:[`item.endDate`]="{ item }">{{item.endDate|formatDate}} - <span v-if="item.endType==0">Matin</span><span v-else>Après-midi</span></template>
      <template v-slot:[`item.description`]="{ item }">{{item.description|truncateDescription}}</template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip v-if="item.status=='STATUT_ACCEPTE'" class="ma-2" color="green" text-color="white">{{item.status}}</v-chip>
        <v-chip v-else-if="item.status=='STATUT_CREER'" class="ma-2" color="warning" text-color="white">{{item.status}}</v-chip>
        <v-chip v-else class="ma-2" color="red" text-color="white">{{item.status}}</v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
            <v-container>
                <ul class="nav nav-tabs" style="font-size: 15px">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#historique"><b>Historique</b></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#description"><b>Description</b></a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade active show" id="historique">
                        <ul class="timeline list-unstyled" v-for="commentaire in item.commentaire" :key="commentaire.id">
                            <li v-if="commentaire.status=='STATUT_ACCEPTE'" class="timeline-item bg-white rounded ml-3 p-4 shadow">
                                <div class="timeline-arrow"></div>
                                <h2 class="contentText mb-0">La demande a été <b>acceptée</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b> avec pour message : {{ commentaire.texte }}</h2>
                                <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                            </li>
                            <li v-else-if="commentaire.status=='STATUT_CREER'" class="timeline-item bg-white rounded ml-3 p-4 shadow">
                                <div class="timeline-arrow"></div>
                                <h2 class="contentText mb-0">La demande a été <b>créer</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b></h2>
                                <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                            </li>
                            <li v-else-if="commentaire.status=='STATUT_REFUSE'" class="timeline-item bg-white rounded ml-3 p-4 shadow">
                                <div class="timeline-arrow"></div>
                                <h2 class="contentText mb-0">La demande a été <b>refusé</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b> avec pour message : {{ commentaire.texte }}</h2>
                                <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                            </li>
                            <li v-else class="timeline-item bg-white rounded ml-3 p-4 shadow">
                                <div class="timeline-arrow"></div>
                                <h2 class="contentText mb-0">La demande a été <b>supprimée</b> par <b>{{ commentaire.utilisateur.firstName }} {{ commentaire.utilisateur.lastName }}</b> avec pour message : {{ commentaire.texte }}</h2>
                                <span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>{{ commentaire.createdAt|formatDate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade show" id="description">
                        <div class="jumbotron mt-3">
                        {{item.description}}
                        </div>
                    </div>
                </div>
            </v-container>
        </td>
      </template>
      <template slot="footer.page-text" slot-scope="item">
        {{item.pageStart}}-{{item.pageStop}} sur {{item.itemsLength}}
      </template>
    </v-data-table>

    <v-data-table
      :headers="headersForHours"
      :items="hours"
      :items-per-page="5"
      :loading="loading"
      sort-by="date"
      :sort-desc="true"
      loading-text="Chargement... Veuillez patienter"
      class="elevation-1"
      style="margin-top: 65px"
      :footer-props="{
        itemsPerPageAllText: 'Tout',
        itemsPerPageText: 'Lignes par page :',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gestion des heures supplémentaires</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-list-item-icon>
            <v-list-item>
                <v-icon color="red" @click="deleteItemHours(item)">mdi-delete</v-icon>
            </v-list-item>
        </v-list-item-icon>
      </template>
      <template v-slot:[`item.auteur`]="{ item }">{{ item.auteur.firstName }} {{ item.auteur.lastName }}</template>
      <template v-slot:[`item.date`]="{ item }">{{item.date|formatDate}}</template>
      <template v-slot:[`item.nbHours`]="{ item }">{{item.hours}}</template>
      <template v-slot:[`item.description`]="{ item }" >
        <p class="mt-4" v-if="item.description==''">Pas de commentaire</p>
        <p class="mt-4" v-else>{{item.description}}</p>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="700px">
      <v-card>
            <v-card-title class="headline">Êtes-vous sûr de vouloir supprimmer la demande ?</v-card-title>
            <v-card-text>
                <v-text-field v-model="raisonDelete" label="Raison de la demande de suppression"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteHours" max-width="930px">
      <v-card>
            <v-card-title class="headline">Êtes-vous sûr de vouloir supprimmer cette déclaration d'heures supplémentaires ?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDeleteHours">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemHoursConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRefuse" max-width="700px">
      <v-card>
            <v-card-title class="headline">Êtes-vous sûr de vouloir refuser la demande ?</v-card-title>
            <v-card-text>
                <v-text-field v-model="raisonRefuse" label="Raison du refus"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeRefuse">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="refuseItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogValid" max-width="570px">
      <v-card>
            <v-card-title class="headline">Êtes-vous sûr de vouloir valider cette demande ?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeValide">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="valideItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" absolute top >{{ text }}</v-snackbar>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data () {
        return {
            search: '',
            employeFilterValue: null,
            statutFilterValue: null,
            loading: true,
            color: '',
            snackbar: false,
            text: '',
            timeout: 4000,
            menuStartDate: false,
            menuEndDate: false,
            dialogNew: false,
            dialogDelete: false,
            dialogRefuse: false,
            dialogValid: false,
            dialogDeleteHours: false,
            editedIndex: -1,
            editedItem: [],
            raisonDelete: '',
            raisonRefuse: '',
            headers: [
                { text: 'Employé', align: 'start', value: 'utilisateur', filter: this.employeFilter},
                { text: 'Motif de l\'absence', value: 'type',filterable: false },
                { text: 'Description', value: 'description'},
                { text: 'Date de début', value: 'startDate',filterable: false },
                { text: 'Date de fin', value: 'endDate',filterable: false },
                { text: 'Durée en jours', value: 'duration',filterable: false },
                { text: 'Etat de la demande', value: 'status', filter: this.statutFilter},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headersForHours: [
                { text: 'Employé', align: 'start', value: 'auteur'},
                { text: 'Date', value: 'date', },
                { text: 'Commentaire', value: 'description', sortable: false },
                { text: 'Nombres d\'heures supplémentaires ce jour', value: 'nbHours', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            demandes: [],
            hours: [],
            types: [],
            listUtilisateurs: [],
            listStatus: [
                '',
                'STATUT_CREER',
                'STATUT_ACCEPTE',
                'STATUT_REFUSE',
                'STATUT_SUPPRIME'
            ],
            demande: {
                type: '',
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                startType: 0, 
                endType: 1, 
                commentaire: '',
                status: 'STATUT_CREER',
                idGoogleEvent: '',
                duration: 1,
                description: '',
                hours: '',
            }
        }
    },

    mounted() {
        // Vérification du role ADMIN
        axios
          .get('/v2/agenda/isadmin')
          .then((response)=>{
            if(
                !response.data.success // erreur dans la récupération du role
                || (response.data.success && !response.data.message) // vérification du role ADMIN de l'utilisateur
                ){
                // redirection vers agenda
                this.$router.push('/agenda');
            }
          })
        this.updateTable();
        this.updateTableHours();

        axios
            .get("/v2/agenda/type")
            .then((response) => {
                if(response.data.success){
                    this.types = response.data.message;
                }else{
                    this.color = "warning"
                    this.text = "Erreur au chargement. Merci de recharger la page."
                    this.snackbar = true;
                }
            })
            .catch(function(error){console.log(error)});
        // Update la liste des users
        axios
          .get('/v2/agenda/list/users')
          .then((response)=>{
            this.listUtilisateurs.push('')
            response.data.message.forEach(user => {
              this.listUtilisateurs.push(user.firstName+' '+user.lastName)
            });
          })
    },

    methods: {
        updateTable() {
            this.loading = true
            axios
            .get("/v2/agenda/demande/all")
            .then((response) => {
                this.loading = false
                if(response.data.success){
                this.demandes = response.data.message;
                this.color = "success"
                this.text = "Toutes les données ont bien été chargées !"
                this.snackbar = true;
                }else{
                this.color = "warning"
                this.text = response.data.message
                this.snackbar = true;
                }
            })
            .catch(this.logResponseErrors);

            axios
            .get("/v2/agenda/isconnected")
            .then((response) => {
                if(!response.data.success){
                    let url = response.data.message.targetUrl;
                    window.location = url;
                }
            })
            .catch(this.logResponseErrors);
        },

        updateTableHours() {
            this.loading = true
            axios
            .get("/v2/heures/list/hours-all")
            .then((response) => {
                this.loading = false
                if(response.data.success){
                this.hours = response.data.message;
                this.color = "success"
                this.text = "Toutes les données ont bien été chargées !"
                this.snackbar = true;
                }else{
                this.color = "warning"
                this.text = response.data.message
                this.snackbar = true;
                }
            })
            .catch(this.logResponseErrors);
        },

        employeFilter(value) {
            if (this.employeFilterValue === null || this.employeFilterValue === '') { return true;}
            return value.firstName+" "+value.lastName === this.employeFilterValue;
        },

        statutFilter(value) {
            if (this.statutFilterValue === null || this.statutFilterValue === '') { return true;}
            return value === this.statutFilterValue;
        },

        close() {
            this.dialogNew = false
        },

        closeDelete() {
            this.dialogDelete = false
        },

        closeDeleteHours() {
            this.dialogDeleteHours = false
        },

        closeRefuse() {
            this.dialogRefuse = false
        },

        closeValide() {
            this.dialogValid = false
        },

        deleteItem (item) {
            this.editedIndex = item.id
            this.editedItem = item
            this.dialogDelete = true
        },

        refuseItem (item) {
            this.editedIndex = item.id
            this.editedItem = item
            this.dialogRefuse = true
        },

        validateItem (item) {
            this.editedIndex = item.id
            this.editedItem = item
            this.dialogValid = true
        },

        deleteItemHours (item) {
            this.editedIndex = item.id
            this.dialogDeleteHours = true
        },

        deleteItemHoursConfirm() {
            axios
                .post("/v2/heures/delete/"+this.editedIndex)
                .then((response) => {
                    if(response.data.success){
                        this.updateTableHours();
                        this.closeDeleteHours()
                    }
                })
                .catch(this.logResponseErrors);
        },

        deleteItemConfirm(){
            let data = new FormData();
            data.set("status", "STATUT_SUPPRIME");
            data.set("texte", (this.raisonDelete.slice(-1)==".")?this.raisonDelete:this.raisonDelete+".");
            axios
            .post("/v2/agenda/demande/addstatus/"+this.editedIndex, data)
            .then((response) => {
                if(response.data.success){
                    this.updateTable();
                    this.closeDelete()
                }else{
                  this.color = "warning"
                  this.text = response.data.message
                  this.snackbar = true;
                }
            })
            .catch(this.logResponseErrors);

            let dataGoogle = new FormData(); 
            dataGoogle.set("email", this.editedItem.utilisateur.email);
            axios
            .post("/v2/agenda/event/delete/"+this.editedItem.id, dataGoogle)
            .then((response) => {
                this.color = (response.data.success) ? "success" : "warning" ;
                this.text = response.data.message
                this.snackbar = true;
            })
            .catch(this.logResponseErrors);
        },

        refuseItemConfirm(){
            let data = new FormData();
            data.set("status", "STATUT_REFUSE");
            data.set("texte", (this.raisonRefuse.slice(-1)==".")?this.raisonRefuse:this.raisonRefuse+".");
            axios
            .post("/v2/agenda/demande/addstatus/"+this.editedIndex, data)
            .then((response) => {
                if(response.data.success){
                    this.updateTable();
                    this.closeRefuse()
                }else{
                  this.color = "warning"
                  this.text = response.data.message
                  this.snackbar = true;
                }
            })
            .catch(this.logResponseErrors);
        },

        valideItemConfirm(){
            let data = new FormData();
            data.set("status", "STATUT_ACCEPTE");
            data.set("texte", "Demande validée.");
            axios
            .post("/v2/agenda/demande/addstatus/"+this.editedIndex, data)
            .then((response) => {
                if(response.data.success){
                    this.updateTable();
                    this.closeValide()
                }else{
                  this.color = "warning"
                  this.text = response.data.message
                  this.snackbar = true;
                }
            })
            .catch(this.logResponseErrors);

            let dataGoogle = new FormData(); 
            dataGoogle.set("title", this.editedItem.type.name)
            dataGoogle.set("email", this.editedItem.utilisateur.email);
            dataGoogle.set("startDate", this.editedItem.startDate.substr(0, 10));
            dataGoogle.set("description", 
                "Du " + this.editedItem.startDate.substr(0, 10) + " " + (this.editedItem.startType?"après-midi":"matin") + " au " + this.editedItem.endDate.substr(0, 10) + " " + (this.editedItem.endType?"après-midi":"matin") +
                " \nDemande validée le "+(new Date()).toISOString().substr(0, 10)+" par "+this.editedItem.utilisateur.firstName+" "+this.editedItem.utilisateur.lastName
            );
            if(this.editedItem.startDate != this.editedItem.endDate){
                let date = new Date(this.editedItem.endDate)
                date.setDate((date.getDate()+1))
                this.editedItem.endDate = date.toISOString()
            }
            dataGoogle.set("endDate", this.editedItem.endDate.substr(0, 10));
            axios
            .post("/v2/agenda/event/add/"+this.editedItem.id, dataGoogle)
            .then((response) => {
              this.color = (response.data.success) ? "success" : "warning" ;
              this.text = response.data.message
              this.snackbar = true;
            })
            .catch(this.logResponseErrors);
        },

        updateDuration() {
            let dateDebut= new Date(this.demande.startDate).getTime();
            let dateFin= new Date(this.demande.endDate).getTime();
            let difference= (dateFin-dateDebut);
            difference = difference/((1000*3600)*24);
            if(this.demande.startType === this.demande.endType){
            difference = difference+0.5;
            }else if(!this.demande.startType && this.demande.endType){
            difference = difference+1;
            }
            this.demande.duration=difference;
        },
    },
    watch: {
        'demande.startDate': {
            handler: function (){
            if (new Date(this.demande.startDate) > new Date(this.demande.endDate)) {
                this.demande.endDate = this.demande.startDate
            }
            this.updateDuration();
            }
        },
        'demande.endDate': {
            handler: function (){
            if (new Date(this.demande.startDate) > new Date(this.demande.endDate)) {
                this.demande.startDate = this.demande.endDate
            }
            this.updateDuration();
            }
        },
        'demande.startType': {
            handler: function (){
            this.updateDuration();
            }
        },
        'demande.endType': {
            handler: function (){
                this.updateDuration();
            }
        }
    }
  }
</script>

<style>
ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
}

ul.timeline:before {
    content: ' ';
    background: #1976d2;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
}

li.timeline-item {
    margin: 20px 0;
    font-size: 15px
}

.timeline-arrow {
    border-top: 0.5rem solid transparent;
    border-right: 0.5rem solid #fff;
    border-bottom: 0.5rem solid transparent;
    display: block;
    position: absolute;
    left: 2rem;
}

.contentText {
  font-size: 15px;
  font-weight: initial;
}

li.timeline-item::before {
    content: ' ';
    background: #fff;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #1976d2;
    left: 11px;
    width: 14px;
    height: 14px;
    z-index: 400;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

</style>