<template>
  <v-card class="table-site">
    <!-- DONNEES -->
    <v-data-table
        :headers="headers"
        :items="computedSites"
        :loading="loading"
        loading-text="Chargement... Veuillez patienter"
        :search="search"
        :footer-props="{
        itemsPerPageAllText: 'Tout',
        itemsPerPageText: 'Lignes par page :',
      }"
        class="elevation-1 my-2 horizontal-scroll"
    >
      <!-- Nom client -->
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Hosting</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-row class="mt-5">
            <v-col cols="3">
              <v-select :items="listCMS" v-model="cmsFilterValue" label="CMS" />
            </v-col>
            <v-col cols="3">
              <v-select :items="listServeurs" v-model="serveursFilterValue" label="Serveurs" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line hide-details />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template #item.client="{ item }">
        <div class="hori">
          <Client :client="item.client" @update="updateTable(baseURL)"/>
        </div>
      </template>

      <!-- URL DU SITE -->
      <template #item.url="{ item }">
        <v-chip
            v-if="item.url != null"
            link
            :href="'http://' + item.url"
            target="_blank"

            title="Visiter"
            color="indigo"
            text-color="white"
        >
          <v-icon left>mdi-link</v-icon>
          {{ item.url }}
        </v-chip>
      </template>

      <!-- CONTACTS -->
      <template #item.contacts="{ item }">
        <v-list v-if="item.client != null && item.client.contacts != null" v-for="(contact, i) in item.client.contacts" :title="contact.nom" dense shaped>
          <Contact
              :key="i"
              :contact="contact"
              @update="updateTable(baseURL)"
          />
        </v-list>
      </template>

      <!-- SERVEUR -->
      <template #item.serveur.nomServeur="{ item }">
        <div style="min-width: 150px;">
          <v-chip
              v-if="item.serveur != null && item.serveur.nomServeur != null"
              label
              color="blue"
              text-color="white"
          >
            <Serveur
            :serveur="item.serveur.nomServeur"/>
          </v-chip>
        </div>
      </template>

      <!-- Jira -->
      <template #item.jira="{ item }">
        <v-chip
            v-if="item.jira != null"
            link
            :href="item.jira"
            target="_blank"
            :title="item.jira"
            color="indigo"
            text-color="white"
        >
          <v-icon left>mdi-link</v-icon>
        </v-chip>
      </template>

      <!-- Voir contrat -->
      <template #item.contrat="{ item }">
        <Contrat
            :id="item.id"
            :nom="item.nomSite"
            :heures="item.contrat ? item.contrat.heures : null"
            :qui="item.contrat ? item.contrat.qui : null"
            :actions="item.contrat ? item.contrat.actions : null"
            :infos="item.contrat ? item.contrat.infos : null"
            :contrat="item.contrat ? item.contrat : null"
            :date="item.contrat ? item.contrat.date : null"
            :budget="item.contrat ? item.contrat.budget : null"
        />
      </template>

      <!-- Modifier Site -->
      <template #item.site="{ item }">
        <Site
            :id="item.id"
            :nom="item.nomSite"
            :url="baseURLListing"
            @update="updateTable(baseURL)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios';
import Contact from './Contact.vue';
import Notebook from './Notebook.vue';
import Client from './Client.vue';
import Site from './Site.vue';
import Tasks from './Tasks.vue';
import Hours from './Hours.vue';
import Contrat from "./Contrat.vue";
import Serveur from "./Serveur.vue";
export default {
  name: "TableSite",
  components: {
    Serveur,
    Contrat,
    Contact,
    Notebook,
    Client,
    Site,
    Tasks,
    Hours,
  },
  data() {
    return {
      baseURL: "/v1/sites",
      cmsFilterValue: null,
      serveursFilterValue: null,
      source: null,
      sites: [],
      totalSites: 0,
      loading: true,
      reqCount: 0,
      search: "",
      filter1: "",
      itemsFilter1: [],
      filter2: "",
      itemsFilter2: [],
      filter3: "",
      itemsFilter3: [],
      baseURLListing: "/admin/?entity=Site&action=edit&referer=/&id=",
      headers: [
        { title: "Client", align: "start", key: "client", },
        { title: "URL", key: "url", },
        { title: "Contacts", key: "contacts", filterable: false },
        { title: "Serveur", key: "serveur.nomServeur", filter: this.serveursFilter},
        { title: "CMS", key: "cms.nomCMS", filter: this.cmsFilter },
        { title: "DNS", key: "dns", filterable: false},
        { title: "Taille", key: "taille", filterable: false},
        { title: "Jira", key: "jira", filterable: false, sortable: false},
        { title: "Afficher contrat", key: "contrat", sortable: false, filterable: false },
        { title: "Modifier site", key: "site", sortable: false, filterable: false },
      ],
    };
  },
  methods: {

    cmsFilter(value) {
      if (this.cmsFilterValue === null || this.cmsFilterValue === '' ) { return true;}
      return value === this.cmsFilterValue;
    },

    serveursFilter(value) {
      if (this.serveursFilterValue === null || this.serveursFilterValue === '' ) { return true;}
      return value === this.serveursFilterValue;
    },

    cancelAxiosRequests() {
      this.source.cancel();
      this.source = null;
    },

    updateTable(url) {
      const axiosSource = axios.CancelToken.source();
      this.source = axiosSource;
      this.reqCount = 0;
      this.loading = true;

      axios
          .get(url) // On récupère l'ensembles de site
          .then((response) => {
            this.sites = response.data.sites; // on enregistre les site dans la propriété sites
            this.source = axiosSource;
            this.totalSites = response.data.total;
          })
          .catch(this.logResponseErrors);
      this.loading = false
    },

    // Converti des minutes en format string "XXhYY"
    minutesToHours(totalMinutes){
      let hours = 0;
      let minutes = 0;
      if(!isNaN(totalMinutes)){
        hours = Math.floor(totalMinutes / 60);
        minutes = totalMinutes % 60;
      }
      if (minutes < 10) minutes = "0" + minutes;
      return hours + "h" + minutes ;
    },

    logResponseErrors(err) {
      if (axios.isCancel(err)) {
        console.log("Request cancelled");
      } else console.error(err);
      this.loading = false
    },

    addFilters(site) {
      const nomServeur = site.serveur ? site.serveur.nomServeur : null;
      const nomClient = site.client ? site.client.nomClient : null;
      const nomCMS = site.cms ? site.cms.nomCMS : null;

      if (nomServeur) {
        if (this.itemsFilter1.indexOf(nomServeur) === -1) {
          this.itemsFilter1.push(nomServeur);
          this.itemsFilter1.sort();
        }
      }

      if (nomClient) {
        if (this.itemsFilter2.indexOf(nomClient) === -1) {
          this.itemsFilter2.push(nomClient);
          this.itemsFilter2.sort();
        }
      }

      if (nomCMS) {
        if (this.itemsFilter3.indexOf(nomCMS) === -1) {
          this.itemsFilter3.push(nomCMS);
          this.itemsFilter3.sort();
        }
      }
    },
  },
  computed: {
    computedSites() {
      let sites = this.sites;
      const filter1 = this.filter1;
      const filter2 = this.filter2;
      const filter3 = this.filter3;

      if (filter1) {
        sites = sites.filter((site) => {
          return site.serveur && site.serveur.nomServeur === filter1;
        });
      }
      if (filter2) {
        sites = sites.filter((site) => {
          return site.client && site.client.nomClient === filter2;
        });
      }
      if (filter3) {
        sites = sites.filter((site) => {
          return site.cms && site.cms.nomCMS === filter3;
        });
      }
      this.totalSites = sites.length;
      return sites;
    },
    listServeurs() {
      let serverList = [];
      serverList.push("");
      this.sites.forEach((server, key) => {
        if (!serverList.includes(server.serveur.nomServeur.toString())) {
          serverList.push(server.serveur.nomServeur.toString());
        }
      })
      return serverList;
    },
    listCMS() {
      let cmsList = [];
      cmsList.push("");
      this.sites.forEach((cms, key) => {
        if (!cmsList.includes(cms.cms.nomCMS.toString())) {
          cmsList.push(cms.cms.nomCMS.toString());
        }
      })
      return cmsList;
    },
  },
  mounted() {
    this.updateTable(this.baseURL);
  }
};
</script>

<style scoped>
.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.hori {
  width: 100px;
  white-space: nowrap;
}

a:hover,
a *:hover {
  cursor: pointer;
}

</style>
