<template>
  <v-dialog v-model="dialog" width="1500">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on" @click="this.dialog = true; computedPing" class="pl-0">
        <v-list-item-media class="mx-auto">
          <v-icon left>mdi-dns</v-icon>
          {{ serveur }}
        </v-list-item-media>
      </v-list-item>
    </template>

    <v-card class="table-site" style="overflow: hidden">
      <v-row>
        <v-col cols="6">

          <v-data-table
              :headers="headersPing"
              :items="pingResponse"
              :loading="loading"
              loading-text="Chargement... Veuillez patienter (cela peut prendre plusieurs minutes)"
              :footer-props="{
              itemsPerPageAllText: 'Tout',
              itemsPerPageText: 'Lignes par page :',
            }"
              class="elevation-1 my-2 horizontal-scroll table-scroll"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Serveur {{ serveur }} ping</v-toolbar-title>
              </v-toolbar>
            </template>

            <template #item.ip="{ item }">
              <v-chip v-if="item.status === 'Joignable'" color="green">
                {{ item.ip }}
              </v-chip>
              <v-chip v-else color="red">
                {{ item.ip }}
              </v-chip>
            </template>

            <template #item.nom="{ item }">
              <v-chip v-if="item.status === 'Joignable'" color="green">
                {{ item.serverName }}
              </v-chip>
              <v-chip v-else color="red">
                {{ item.serverName }}
              </v-chip>
            </template>

            <template #item.status="{ item }">
              <v-chip v-if="item.status === 'Joignable'" color="green">
                {{ item.status }}
              </v-chip>
              <v-chip v-else color="red">
                {{ item.status }}
              </v-chip>
            </template>

          </v-data-table>
        </v-col>

        <v-col cols="6">
          <v-data-table
              :headers="headersQuotas"
              :items="quotasResponse"
              :loading="loading"
              loading-text="Chargement... Veuillez patienter (cela peut prendre plusieurs minutes)"
              :footer-props="{
              itemsPerPageAllText: 'Tout',
              itemsPerPageText: 'Lignes par page :',
            }"
              class="elevation-1 my-2 horizontal-scroll table-scroll"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Serveur {{ serveur }} quotas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-row class="mt-5">
                  <v-col cols="6">
                    <v-text-field v-model="showTotalServerSpace" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="showFreeServerSpace" />
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template #item.user="{ item }">
              <v-chip :color="getUsageColor(item.used, item.limit)">
                {{ item.user }}
              </v-chip>
            </template>

            <template #item.used="{ item }">
              <v-chip :color="getUsageColor(item.used, item.limit)">
                {{ item.used }}
              </v-chip>
            </template>

            <template #item.limit="{ item }">
              <v-chip :color="getUsageColor(item.used, item.limit)">
                {{ item.limit }}
              </v-chip>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
  name: "Serveur",
  props: {
    serveur: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      loading: true,
      response: null,
      pingResponse: [],
      quotasResponse: [],
      headersPing: [
        { title: "IP", align: "start", key: "ip", },
        { title: "Nom site", key: "nom", },
        { title: "Status", key: "status", },
      ],
      headersQuotas: [
        { title: "Utilisateur", align: "start", key: "user", },
        { title: "Utilisé", key: "used", },
        { title: "Limite", key: "limit", },
      ],
    };
  },
  methods: {
    convertMBtoKB(mb) {
      return mb * 1000;
    },
    getUsageColor(used, limit) {
      let usedKB;
      let limitKB;
      if (used.toString().endsWith('M')) {
        usedKB = this.convertMBtoKB(parseInt(used));
      } else {
        usedKB = parseInt(used);
      }
      if (limit.toString().endsWith('M')) {
        limitKB = this.convertMBtoKB(parseInt(limit));
      } else {
        limitKB = parseInt(used);
      }
      const usagePercentage = usedKB / limitKB;
      return usagePercentage >= 0.90 ? 'red' : 'green';
    }
  },
  computed: {
    computedPing() {
      this.loading = true;
      axios.get('/v1/ping', {
        params: {
          serveur: this.serveur
        }
      }).then((response) => {
        this.response = response.data.data;
        this.pingResponse = this.response.servers;
        this.quotasResponse = this.response.quota;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    showTotalServerSpace() {
      if (this.response === null) {
        return "chargement...";
      }
      return `taille totale: ${this.response.space.total}`;
    },
    showFreeServerSpace() {
      if (this.response === null) {
        return "chargement...";
      }
      return `taille dispo: ${this.response.space.free}`;
    },
  },
};
</script>

<style scoped>
.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.hori {
  width: 100px;
  white-space: nowrap;
}

a:hover,
a *:hover {
  cursor: pointer;
}

.table-scroll {
  max-height: 600px;
  overflow-y: auto;
}

</style>
