<template>
    <v-container>
        <div class="jumbotron">
            <h1 class="display-3 mb-4">Exportation des absences</h1>
            <p class="lead">Sélectionner un moi dans le calendrier pour afficher toutes les absences le concernant.</p>
            <hr class="my-4">
            <v-row>
                <v-col style="max-width:350px" class="mt-5">
                    <v-date-picker locale="fr-fr" v-model="dateExport" type="month"></v-date-picker>
                </v-col>
                <v-col style="width:100%" class="mt-5">
                    <v-container class="pt-0">
                        <ul class="nav nav-tabs" style="font-size: 15px">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#abs"><b>Absences</b></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#temps"><b>Temps de travail</b></a>
                        </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade active show" id="abs">  
                                <v-simple-table v-if="!loading">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Prénom
                                                </th>
                                                <th class="text-left">
                                                    Nom
                                                </th>
                                                <th class="text-left">
                                                    Motif de l'absence
                                                </th>
                                                <th class="text-left">
                                                    Durée en jours
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="demande in demandes" :key="demande.id" >
                                                <td>{{ demande.firstName }}</td>
                                                <td>{{ demande.lastName }}</td>
                                                <td>{{ demande.name }}</td>
                                                <td>{{ demande.duree }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <div class="tab-pane fade show" id="temps">
                                <h5 v-if="!loading" class="mt-5 mb-5" style="padding-left:30px">Il y a {{ jourDeTravail }} jours ouvrés ce mois-ci.</h5>
                                <v-simple-table v-if="!loading">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Prénom
                                                </th>
                                                <th class="text-left">
                                                    Nom
                                                </th>
                                                <th class="text-left">
                                                    Jours travaillés dans le mois 
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="absence in absences" :key="absence.email" >
                                                <td>{{ absence.firstName }}</td>
                                                <td>{{ absence.lastName }}</td>
                                                <td>{{ jourDeTravail - absence.duree }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </v-container>

                </v-col>
            </v-row>
            <button type="button" class="btn btn-primary btn-lg mt-4">Exporter</button>
        </div>
    </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    data () {
        return {
            demandes:[],
            users:[],
            absences:[],
            dateExport:'',
            jourDeTravail:'',
            loading: false,
        }
    },

    mounted() {
        // Vérification du role ADMIN
        axios
          .get('/v2/agenda/isadmin')
          .then((response)=>{
            if(
                !response.data.success // erreur dans la récupération du role
                || (response.data.success && !response.data.message) // vérification du role ADMIN de l'utilisateur
                ){
                // redirection vers agenda
                this.$router.push('/agenda');
            }
          })
        this.dateExport = new Date().getFullYear()+'-'+(new Date().getMonth()+1);
    },

    methods: {
        updateTable() {
            let date = this.dateExport.split('-');
            let nombreJours = 0;
            this.loading = true;
            axios
                .get("/v2/agenda/demande/export/"+date[0]+'/'+date[1])
                .then((response) => {
                    if(response.data.success){
                        this.demandes = response.data.message;

                        this.absences = this.demandes.reduce((jours, demande) => {
                            const jour = (jours[demande.email] || {
                                firstName : demande.firstName,
                                lastName : demande.lastName,
                                email : demande.email,
                                duree : 0
                            });
                            jour.duree += parseInt(demande.duree);
                            jours[demande.email] = jour;
                            return jours;
                        }, {});

                        // Nombre de jours en fonction des mois
                        if((date[1] == 1) || (date[1] == 3) || (date[1] == 5) || (date[1] == 7) || (date[1] == 8) || (date[1] == 10) || (date[1] == 12)){
                            nombreJours = 31;
                        }else if((date[1] == 4) || (date[1] == 6) || (date[1] == 9) || (date[1] == 11)) {
                            nombreJours = 30;
                        }else{ // Annee bissextile
                            if ((date[0] % 4 === 0 && date[0] % 100 > 0) || (date[0] % 400 === 0)) {
                                nombreJours = 29;
                            } else {
                                nombreJours = 28;
                            }
                        }

                        // Calcul du nombre total de jours semaines
                        let total = 0;
                        for(let i = 1; i <= nombreJours; i++){
                            total = total + (new Date(date[0], date[1]-1, i.toString()).getDay() % 6 == 0 ? 0 : 1);
                        }
                        
                        // Récupération des jours fériés Français sur api.gouv.fr
                        axios
                            .get("https://calendrier.api.gouv.fr/jours-feries/metropole/"+date[0]+".json")
                            .then((response) => {
                                // Pour tous les jours fériés on regarde si il correspond au mois au courant et si ce n'est pas un W.E
                                Object.keys(response.data).forEach((element) => {
                                    const holiday = element.split('-');
                                    if(holiday[1] == (date[1]) && new Date(element).getDay()%6 != 0){
                                        total = total - 1
                                    }
                                })
                                this.jourDeTravail = total;
                                this.loading = false;
                            })      
                    }else{
                        alert('erreur de chargement...');
                    }
                })
                .catch(error => {console.log(error);});
        },
    },
    watch: {
        dateExport: {
            handler: function (){
                this.updateTable();
            }
        }
    }
  }
</script>

<style>

.v-picker__title__btn div {
    text-transform: capitalize;
}

</style>